import {StyleSheet, Text, View} from 'react-native';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

const styles = StyleSheet.create({
  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 9999,
  },
  message: {
    color: '#fff',
    fontSize: 18,
    textAlign: 'center',
  },
  icon: {
    fontSize: 48,
    color: '#fff',
    marginBottom: 16,
  },
});

const RotateYourPhone = () => {

  return (
      <View style={styles.messageContainer}>
        <ScreenRotationIcon style={styles.icon}/>
        <Text style={styles.message}>Bitte wechseln Sie in den Portrait-Modus.</Text>
      </View>

  );
};
export default RotateYourPhone;