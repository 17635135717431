import {Text, View, FlatList, Image, StyleSheet, TouchableOpacity} from 'react-native';
import React, {useEffect, useState} from 'react';
import {Barlow} from '../../utils/theme';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const HomeScreenObject = ({continent, image, objects}) => {

  const styles = StyleSheet.create({
    listItemContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      padding: 20,
    },
    listItemImage: {
      width: 90,
      height: 90,
    },
    listItemTextContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
    },
    listItemText: {
      fontFamily: Barlow.typography.fontFamily,
      fontWeight: Barlow.typography.fontWeightMedium,
      color: '#28465A',
      fontSize: 15,
      marginLeft: 20,
      flex: 1,
    },
    listItemUpperText: {
      fontFamily: Barlow.typography.fontFamily,
      fontWeight: Barlow.typography.fontWeightMedium,
      color: '#28465A',
      fontSize: 20,
      marginLeft: 20,
      flex: 1,
    },
  });

  return (

      <View style={{...styles.listItemContainer, borderBottomWidth: 2, borderBottomColor: '#C8D3D9'}}>
        <Image source={image} style={styles.listItemImage}/>
        <View style={styles.listItemTextContainer}>
          <View style={{flexDirection: 'column'}}>
            <Text style={styles.listItemUpperText}>{continent}</Text>
            <Text style={styles.listItemText}>{objects}</Text>
          </View>
          <ArrowForwardIosOutlinedIcon style={{color: '#28465A', fontSize: 'xx-large'}}/>
        </View>
      </View>
  );
};

export default HomeScreenObject;

