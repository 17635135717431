import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Image,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import currencyFormatDE from '../../utils/currencyFormatDE';
import {Barlow} from '../../utils/theme';
import AssetService from '../../services/AssetService';

const styles = StyleSheet.create({
  detailWrapper: {},
  detailsTitle: {
    fontSize: 20,
    fontWeight: Barlow.typography.fontWeightMedium,
    fontFamily: Barlow.typography.fontFamily,
    marginTop: 30,
    marginBottom: 20,
    color: '#28465A',
  },
  detailsContainer: {
    flexDirection: 'row',
    color: '#28465A',
    fontFamily: Barlow.typography.fontFamily,
    fontWeight: '500',
  },
  detailColumn: {
    flexDirection: 'column',
    width: '50%',
  },
  detailRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 5,
  },
  detailsIcon: {
    marginRight: 15,
    width: 20,
    height: 20,
  },
  textContainer: {
    flexDirection: 'column',
    width: '80%',

  },
  detailsText: {
    fontSize: 14,
    color: '#28465A',
    fontWeight: '600',
    fontFamily: Barlow.typography.fontFamily,
  },
  detailsData: {
    fontSize: 15,
    color: '#28465A',
    fontWeight: '400',
    fontFamily: Barlow.typography.fontFamily,

  },
  InvestWrapper: {
    marginBottom: 20,
  },
  InvestDetailsContainer: {
    flexDirection: 'row',
    color: '#28465A',
    fontFamily: Barlow.typography.fontFamily,
    fontWeight: '500',
    marginBottom: 5,
  },
  InvestDetailsText: {
    fontSize: 16,
    color: '#28465A',
    fontWeight: Barlow.typography.fontWeightBold,
    fontFamily: Barlow.typography.fontFamily,
    width: '50%',
  },
  InvestDetailsData: {
    fontSize: 16,
    color: '#28465A',
    fontWeight: '400',
    fontFamily: Barlow.typography.fontFamily,
  },

});

const currency = {
  1: '€',
  3: 'US$',
  4: 'NOK',
  5: 'RMB',
  6: 'JPY',
  7: 'AUD',
  8: 'GBP',

};

const RealEstateInfoPanel = ({route, navigation, estate}) => {
  const data = estate;

  return (
      <View>
        <View style={styles.detailWrapper}>
          <Text style={styles.detailsTitle}>Details</Text>
          <View style={styles.detailsContainer}>
            <View style={styles.detailColumn}>
              {data.location && (
                  <View style={styles.detailRow}>
                    <Image style={styles.detailsIcon} source={require('../../../assets/icons/apartment-icon.svg')}/>
                    <View style={styles.textContainer}>
                      <Text style={styles.detailsText}>Stadt</Text>
                      <Text style={styles.detailsData}>{data.location}</Text>
                    </View>
                  </View>
              )}
              {data.completion !== '0' && (
                  <View style={styles.detailRow}>
                    <Image style={styles.detailsIcon} source={require('../../../assets/icons/task-icon.svg')}/>
                    <View style={styles.textContainer}>
                      <Text style={styles.detailsText}>Fertigstellung</Text>
                      <Text style={styles.detailsData}>{data.completion}</Text>
                    </View>
                  </View>
              )}
              {data.acquisition !== '0' && (
                  <View style={styles.detailRow}>
                    <Image style={styles.detailsIcon} source={require('../../../assets/icons/monetization-icon.svg')}/>
                    <View style={styles.textContainer}>
                      <Text style={styles.detailsText}>Erwerb</Text>
                      <Text style={styles.detailsData}>{data.acquisition}</Text>
                    </View>
                  </View>
              )}
            </View>
            <View style={styles.detailColumn}>
              {data.street && (
                  <View style={styles.detailRow}>
                    <Image style={styles.detailsIcon} source={require('../../../assets/icons/signpost-icon.svg')}/>
                    <View style={styles.textContainer}>
                      <Text style={styles.detailsText}>Straße</Text>
                      <Text style={styles.detailsData}>{data.street}</Text>
                    </View>
                  </View>
              )}
              {data.renovation !== '0' && (
                  <View style={styles.detailRow}>
                    <Image style={styles.detailsIcon} source={require('../../../assets/icons/construction-icon.svg')}/>
                    <View style={styles.textContainer}>
                      <Text style={styles.detailsText}>Renovierung</Text>
                      <Text style={styles.detailsData}>{data.renovation}</Text>
                    </View>
                  </View>
              )}
              {data.parkinglots !== '0' && (
                  <View style={styles.detailRow}>
                    <Image style={styles.detailsIcon} source={require('../../../assets/icons/parking-icon.svg')}/>
                    <View style={styles.textContainer}>
                      <Text style={styles.detailsText}>Parkplätze</Text>
                      <Text style={styles.detailsData}>{data.parkinglots}</Text>
                    </View>
                  </View>
              )}
            </View>
          </View>
        </View>
        <View style={styles.InvestWrapper}>
          <Text style={styles.detailsTitle}>Investment Details</Text>
          {data.fairvalue !== '0.00' && (
              <View style={styles.InvestDetailsContainer}>
                <Text style={styles.InvestDetailsText}>Fair Value</Text>
                <Text style={styles.InvestDetailsData}>{`${currencyFormatDE(data?.fairvalue)} ${currency[data.currency]}`}</Text>
              </View>
          )}
          {data.totalinvestment !== '0.00' && (
              <View style={styles.InvestDetailsContainer}>
                <Text style={styles.InvestDetailsText}>Total Investment</Text>
                <Text style={styles.InvestDetailsData}>{`${currencyFormatDE(data?.totalinvestment)} ${currency[data.currency]}`}</Text>
              </View>
          )}
          {data.equity !== '0.00' && (
              <View style={styles.InvestDetailsContainer}>
                <Text style={styles.InvestDetailsText}>Equity</Text>
                <Text style={styles.InvestDetailsData}>{`${currencyFormatDE(data?.equity)} ${currency[data.currency]}`}</Text>
              </View>
          )}
          {data.externalloan !== '0.00' && (
              <View style={styles.InvestDetailsContainer}>
                <Text style={styles.InvestDetailsText}>External Loan</Text>
                <Text style={styles.InvestDetailsData}>{`${currencyFormatDE(data?.externalloan)} ${currency[data.currency]}`}</Text>
              </View>
          )}
          {data.externalloanvaluta !== '0.00' && (
              <View style={styles.InvestDetailsContainer}>
                <Text style={styles.InvestDetailsText}>External Loan Valuta</Text>
                <Text style={styles.InvestDetailsData}>{`${currencyFormatDE(data?.externalloanvaluta)} ${currency[data.currency]}`}</Text>
              </View>
          )}
        </View>
      </View>
  );
};

export default RealEstateInfoPanel;
