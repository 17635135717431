import * as React from 'react';
import { useEffect, useRef, useState, useContext } from 'react';
import { View, Text, Button, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import RealEstateService from './../services/RealEstateService';
import HomeScreenObject from '../components/Common/HomescreenObject';
import SCREEN from '../utils/contants/SCREEN';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import App from '../../App';
import { AppContext } from '../contexts/AppContext';
import MPStorageService from '../services/MPStorageService';

const styles = StyleSheet.create({
  searchResult: {
    textAlign: 'center',
    fontSize: 20,
    color: '#C51F40',
    padding: 20
  }
});
export default function Home({ navigation, route }) {
  const [data, setData] = useState([]);
  const flatListRef = useRef(null);
  const [countByContinent, setCountByContinent] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { setActiveTab } = useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      const Storage = new MPStorageService();
      const credentials = await Storage.getCredentials();
      const SERVICE = new RealEstateService(credentials);
      const data = await SERVICE.getAll();
      setData(data || []);
      setIsLoading(false);
    }

    fetchData();
  }, [])

  useEffect(() => {
    const count = data.reduce((acc, cur) => {
      if (cur.continent in acc) {
        acc[cur.continent] += 1;
      } else {
        acc[cur.continent] = 1;
      }
      return acc;
    }, {});
    setCountByContinent(count);
  }, [data]);

  const continents = [
    { code: 'EUR', name: 'Europa', image: require('../../assets/images/europa-kontinent.svg') },
    { code: 'ASI', name: 'Asien', image: require('../../assets/images/asien-kontinent.svg') },
    { code: 'AUS', name: 'Ozeanien', image: require('../../assets/images/ozeanien-kontinent.svg') }
  ];

  const RenderContinents = ({ item }) => {
    const countContinents = countByContinent[item.code] || 0;
    return countContinents > 0
      ? (
        <TouchableOpacity onPress={() => handlePress(item.code)}>
          <HomeScreenObject
            continent={item.name}
            image={item.image}
            objects={`${countContinents} Immobilie${countContinents === 1 ? '' : 'n'}`}
          />
        </TouchableOpacity>

      )
      : null;
  };

  const handlePress = (continentName) => {
    setActiveTab(2);
    navigation.navigate(SCREEN.REAL_ESTATE_LIST, { continent: continentName });
  };

  return (
    <TouchableOpacity onPress={handlePress}>
      <View>
        {isLoading && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <CircularProgress sx={{ color: '#c51f40' }} />
        </Box>}
        {!isLoading && data.length === 0 && <Text style={styles.searchResult}>keine Daten vorhanden</Text>}
        {!isLoading && data.length > 0 && (
          <FlatList
            ref={flatListRef}
            data={continents}
            renderItem={RenderContinents}
            keyExtractor={(item) => item.code}
          />
        )}
      </View>
    </TouchableOpacity>
  );
}
