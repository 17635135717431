/* eslint-disable eqeqeq */
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Highcharts from 'highcharts';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import RealEstateService from '../services/RealEstateService';
import { Barlow, theme } from '../utils/theme';
import HighchartsReact from 'highcharts-react-official';
import MPStorageService from '../services/MPStorageService';

const styles = StyleSheet.create({
  wrapper: {
    width: '75%'
  },
  TabContent: {
    paddingBottom: 10,
    fontSize: 20,
    fontFamily: Barlow.typography.fontFamily,
    fontWeight: Barlow.typography.fontWeightMedium,
    color: theme.palette.secondary.main
  }
});

export default function Analysis({ route, navigation }) {
  const [tabIndex, setTabIndex] = React.useState('1');
  const [data, setData] = useState([]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Immobilienportfolio
  const categories = {
    1: 'Büro',
    2: 'Einzelhandel',
    3: 'Wohnen',
    4: 'Hotel',
    5: 'Logistik',
    6: 'Lager',
    7: 'Sonstiges',
    8: 'Shoppingcenter',
    9: 'Fachmarktzentren',
    10: 'Gastronomie',
    11: 'Medical Center'
  };

  const categoryColors = {
    1: '#a2b6cc',
    2: '#5e7382',
    3: '#8acbf1',
    4: '#cfe3ee',
    5: '#FFA07A',
    6: '#D2B48C',
    7: '#FF7F50',
    8: '#4682B4',
    9: '#556B2F',
    10: '#4B0082',
    11: '#A9A9A9'
  };

  // Assets
  const splits = {
    0: 'Core',
    1: 'Value Add',
    2: 'Core+',
    3: 'Opportunistic'
  };

  const splitsColors = {
    0: '#a2b6cc',
    1: '#5e7382',
    2: '#8acbf1',
    3: '#cfe3ee'
  };

  useEffect(() => {
    async function fetchData() {
      const Storage = new MPStorageService();
      const credentials = await Storage.getCredentials();
      const SERVICE = new RealEstateService(credentials);
      const data = await SERVICE.getAll();
      setData(data);
      const categoryCounts = {};
      data?.forEach(item => {
        const category = categories[item.cruser_id];
        if (category) {
          if (categoryCounts[category]) {
            categoryCounts[category] += 1;
          } else {
            categoryCounts[category] = 1;
          }
        }
      });

      const Fonds = {};
      data?.forEach(item => {
        const split = splits[item.split];
        if (splits) {
          if (Fonds[splits]) {
            Fonds[splits] += 1;
          } else {
            Fonds[splits] = 1;
          }
        }
      });
    }

    fetchData();
  }, []);

  const chartData = Object.entries(categories).map(([key, value]) => ({
    name: value,
    y: data.filter((item) => item.cruser_id == key).length
  })).filter((category) => category.y > 0);

  const chartData2 = Object.entries(splits).map(([key, value]) => ({
    name: value,
    y: data.filter((item) => item.split == key).length
  })).filter((split) => split.y > 0);

  const optionsPortfolio = {
    chart: {
      type: 'pie',
      plotBackgroundColor: '#f2f2f2',
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: '#f2f2f2'
    },
    credits: {
      enabled: false
    },
    accessibility: {
      enabled: false
    },
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true,
        colors: Object.keys(categories).map(key => categoryColors[key])
      }
    },
    legend: {
      labelFormatter: function () {
        return this.name + ' (' + this.y + ')';
      }
    },
    series: [
      {
        name: 'Anzahl der Objekte',
        data: chartData
      }]
  };

  const optionsAssets = {
    chart: {
      type: 'pie',
      plotBackgroundColor: '#f2f2f2',
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: '#f2f2f2'
    },
    credits: {
      enabled: false
    },
    accessibility: {
      enabled: false
    },
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true,
        colors: Object.keys(splits).map(key => splitsColors[key])
      }
    },
    legend: {
      labelFormatter: function () {
        return this.name + ' (' + this.y + ')';
      }
    },
    series: [
      {
        name: 'Anteile',
        data: chartData2
      }]
  };

  return (
    <ScrollView>
      <Box sx={{ width: '100%' }}>
        <TabContext value={tabIndex}>
          <Box
            sx={{
              // roter Balken
              '.MuiTabs-indicator': {
                backgroundColor: theme.palette.secondary.main,
                height: 3
              },
              '.MuiTabs-scroller ': {
                position: 'absolute',
                transform: 'translateY(2px)'
              },
              '.MuiTabs-flexContainer': {
                justifyContent: 'center'
              }
            }}>
            <TabList sx={{ margin: 0 }} onChange={handleChange}>
              <Tab sx={{
                padding: '15px 5px 10px 0',
                borderBottom: 2,
                borderColor: 'divider'
              }} label="IMMOBILIENPORTFOLIO" value="1" />
              <Tab sx={{
                padding: '15px 5px 10px 5px',
                borderBottom: 2,
                borderColor: 'divider'
              }} label="ASSET SPLIT" value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ padding: '14px 10px' }} value="1">
            <Typography style={styles.TabContent}>Immobilien Portfolio</Typography>
            <HighchartsReact highcharts={Highcharts} options={optionsPortfolio} />
          </TabPanel>
          <TabPanel sx={{ padding: '14px 10px' }} value="2">
            <Typography style={styles.TabContent}>Asset Split</Typography>
            <HighchartsReact highcharts={Highcharts} options={optionsAssets} />
          </TabPanel>
        </TabContext>
      </Box>
    </ScrollView>
  );
}
