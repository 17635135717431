import React, {useState, useContext, useEffect} from 'react';
import {
  BottomNavigation, BottomNavigationAction,
} from '@mui/material';
import SCREEN from '../../utils/contants/SCREEN';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import {AppContext} from '../../contexts/AppContext';

const MPBottomNavigationBar = ({state, descriptors, navigation}) => {
  const {setActiveTab, activeTab, setSearchTerm, setIsSearchBarOpen} = useContext(AppContext);

  const resetSearch = () => {
    navigation.setParams({continent: ''});
    setSearchTerm('');
    setIsSearchBarOpen(false);
  };

  return (
      <BottomNavigation
          showLabels
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
          style={{backgroundColor: '#CFE0EB', height: 70}}
      >
        {state.routes.map((route, index) => {
          const {options} = descriptors[route.key];

          let iconComponent = null;
          switch (route.name) {
            case SCREEN.HOME:
              iconComponent = <WidgetsIcon/>;
              break;
            case SCREEN.ANALYSIS:
              iconComponent = <DonutSmallIcon/>;
              break;
            case SCREEN.REAL_ESTATE_LIST:
              iconComponent = <HolidayVillageIcon/>;
              break;
            case SCREEN.PROFILE:
              iconComponent = <AccountCircleOutlinedIcon/>;
              break;

            default:
              break;
          }

          const label =
              options.tabBarLabel !== undefined
                  ? options.tabBarLabel
                  : options.title !== undefined
                      ? options.title
                      : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            resetSearch();
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate({name: route.name, merge: true});
            }
          };

          return <BottomNavigationAction key={route.key} label={label} onClick={onPress} icon={iconComponent}/>;
        })}

      </BottomNavigation>
  );
};

export default MPBottomNavigationBar;
