import Icon from '../../../assets/images/login-header-img.jpg';
import {Image} from 'react-native';

const HeaderImage = ({width, height}) => {
  let _w = width || '100%';
  let _h = height || 208;

  return (
      <Image
          style={{width: _w, height: _h}}
          source={Icon}
      />
  );
};

export default HeaderImage;