import {ThemeProvider} from '@mui/material';
import {NavigationContainer, useNavigation} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {SafeAreaView} from 'react-native';
import MPAppBar from './src/components/Layout/MPAppBar';
import {AppContextProvider} from './src/contexts/AppContext';
import InnerApp from './src/screens/InnerApp';
import Login from './src/screens/Login';
import RealEstateDetail from './src/screens/RealEstateDetail';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
import SCREEN from './src/utils/contants/SCREEN';
import destroyIOSStupidScroll from './src/utils/destroyIOSStupidScroll';
import {theme} from './src/utils/theme';
import {useEffect, useState} from 'react';
import RotateYourPhone from './src/components/Common/RotateYourPhone';

const Stack = createNativeStackNavigator();

export default function App() {
  /** STATIC DEFINITIONEN */
  const screenOptions = {
    title: '',
    headerBackImageSource: null,
    headerShown: false,
  };

  const [isLandscape, setIsLandscape] = useState(false);
  /** HANDLERS */

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(
          window.matchMedia('(orientation: landscape)').matches,
      );
    };

    window.addEventListener('resize', handleOrientationChange);

    setIsLandscape(window.matchMedia('(orientation: landscape)').matches);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  /** STATES */

  return (
      <AppContextProvider>      {/* children component can read AppContext */}
        <NavigationContainer>        {/* children component can use react-navigation */}
          <SafeAreaView style={{flex: 1, backgroundColor: '#cfe0eb'}}>
            {isLandscape ? (
                <RotateYourPhone/>
            ) : null}
            <ThemeProvider theme={theme}>
              <Stack.Navigator initialRouteName={SCREEN.LOGIN}>
                {/* children components are screen components, stacked navigation */}
                <Stack.Screen
                    name={SCREEN.LOGIN}
                    component={Login}
                    options={screenOptions}
                />
                <Stack.Screen
                    name={SCREEN.INNER_APP}
                  component={InnerApp}
                  options={screenOptions}
              />
              <Stack.Group screenOptions={{ presentation: 'modal' }}>
                <Stack.Screen
                  name={SCREEN.REAL_ESTATE_DETAIL}
                  component={RealEstateDetail}
                  options={({ route }) => ({
                    header: (props) => <MPAppBar {...props} tall={true} />,
                    upperText: 'Objekt-Details',
                    realEstateName: route.params ? route.params.name : 'Objekte'
                  })}
                />
              </Stack.Group>
            </Stack.Navigator>
          </ThemeProvider>
        </SafeAreaView>
      </NavigationContainer>
    </AppContextProvider>
  );
}

/**
 * We execute some initialization code before the app is ready
 */
((window) => {
  // iOS support
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  if (isIOS) {
    destroyIOSStupidScroll(window);
  }
})(window);

// offline support
serviceWorkerRegistration.register({scope: '/'});
