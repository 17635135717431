import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Slide, Snackbar, SnackbarContent, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import PasswordChecklist from 'react-password-checklist';
import { useClickAway } from 'react-use';
import RealEstateService from '../services/RealEstateService';
import UserService from '../services/UserService';
import SCREEN from '../utils/contants/SCREEN';
import { Barlow } from '../utils/theme';
import { AppContext } from './../contexts/AppContext';
import { BACKEND_API_GATEWAY } from '../utils/contants/BACKEND_API_GATEWAY';
import AssetService from '../services/AssetService';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import MPStorageService from './../services/MPStorageService';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = StyleSheet.create({
  main: {
    maxWidth: 375,
    alignSelf: 'center',
    paddingVertical: 25,
    paddingHorizontal: 15
  },
  wrapper: {
    borderBottomColor: '#E4E9EC',
    borderBottomWidth: 2
  },
  mainHeader: {
    color: '#28465A',
    fontSize: 22,
    lineHeight: 22,
    fontFamily: Barlow.typography.fontFamily

  },
  username: {
    color: '#28465A',
    fontSize: 22,
    fontFamily: Barlow.typography.fontFamily,
    fontWeight: Barlow.typography.fontWeightBold,
    paddingRight: 15
  },
  header: {
    color: '#28465A',
    fontSize: 18,
    lineHeight: 18,
    paddingTop: 20,
    paddingBottom: 20
  },
  text: {
    marginBottom: 20
  },
  input: {
    marginBottom: 24
  },
  nameBanner: {
    textAlign: 'center',
    lineHeight: 22,
    fontSize: 22,
    paddingBottom: 30,
    borderBottomColor: '#E4E9EC',
    borderBottomWidth: 2
  },
  userData: {
    color: '#28465A',
    fontSize: 18,
    lineHeight: 18,
    paddingTop: 30,
    paddingBottom: 20
  },
  dataInput: {
    width: 345,
    alignSelf: 'center',
    marginBottom: 15
  },
  blueButtons: {
    width: 345,
    height: 50,
    color: '#FFF',
    backgroundColor: '#5C7E94',
    textTransform: 'none'
  },
  redButtons: {
    width: 345,
    height: 50,
    alignSelf: 'center',
    color: '#FFFFFF',
    backgroundColor: '#C51F40',
    textTransform: 'capitalize'
  },
  logout: {
    marginVertical: 30
    // alignSelf: 'baseline',
  }

});

export default function Profile({ route, navigation }) {
  const { appContext, setAppContext, setActiveTab } = useContext(AppContext);
  const { username, id } = appContext.auth;
  const [showSnackbarOffline, setShowSnackbarOffline] = useState(false);
  const [showMessageLoadOffline, setShowMessageLoadOffline] = useState(false);
  const [showSnackbarLoadOfflineDone, setShowSnackbarLoadOfflineDone] = useState(false);
  const [completedRequests, setCompletedRequests] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showSnackbarChangeData, setShowSnackbarChangeData] = useState(false);
  const [showSnackbarError, setShowSnackbarError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showChecklist, setShowChecklist] = useState(false);
  const [formdata, setFormdata] = useState({
    email: appContext.auth.email,
    password: appContext.auth.password
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickInput = () => {
    setShowChecklist(true);
  };

  const handleClickAway = (event) => {
    const target = event.target;
    const isIconButton = target.id === 'toggle-password-visibility' || target.closest('#toggle-password-visibility') || target.id === 'btn_change_data' || target.closest('#btn_change_data');
    if (!isIconButton) {
      setShowChecklist(false);
    }
  };

  const ref = useRef(null);
  useClickAway(ref, handleClickAway);

  const handleLogoutClick = (event) => {
    event.preventDefault();
    setAppContext((prevState) => {
      return {
        ...prevState,
        auth: {
          id: '',
          username: '',
          email: '',
          password: ''
        }
      };
    });
    setActiveTab(0);
    navigation.navigate(SCREEN.LOGIN, {});
  };

  const handleClickOfflineData = async () => {
    const Storage = new MPStorageService();
    const credentials = await Storage.getCredentials();
    const SERVICE = new RealEstateService(credentials);
    setShowMessageLoadOffline(true);
    let numToCache = 0;
    let completedRequests = 0;

    SERVICE.getAll().then((datas) => {
      datas.forEach((data) => {
        const { picture_list_ids } = data;
        picture_list_ids.forEach((file) => {
          const url = BACKEND_API_GATEWAY + '/img/' + file;
          AssetService.saveToCache(url);
          numToCache++;
        });
      });

      const bc = new BroadcastChannel('SW.CACHED.NOTIFICATION');
      const handleCached = (event) => {
        numToCache--;
        completedRequests++;
        console.log(`Completed requests: ${completedRequests}`);

        setCompletedRequests((prev) => prev + 1);
        if (completedRequests === total) {
          bc.removeEventListener('message', handleCached);
          // caching done
          setShowMessageLoadOffline(false);
          setShowSnackbarLoadOfflineDone(true);
          if (!showMessageLoadOffline) {
            setCompletedRequests(0);
          }
          setTimeout(() => setShowSnackbarLoadOfflineDone(false), 3000);
        }
      };

      bc.addEventListener('message', handleCached);
      const total = numToCache;
      setTotalCount(total);
    });
  };

  const handleClickChangeData = async () => {
    setShowSnackbarChangeData(true);
    if (!navigator.onLine) {
      setShowSnackbarOffline(true);
      setTimeout(() => setShowSnackbarOffline(false), 3000);
      setShowSnackbarChangeData(false);
      return;
    }
    const SERVICE = new UserService();
    const updateData = {
      id: appContext.auth.id
    };
    let emailValid = false;
    let passwordValid = false;

    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (formdata?.email && formdata.email.match(emailRegex)) {
      updateData.email = formdata.email;
      emailValid = true;
    } else {
      setShowSnackbarChangeData(false);
      setShowSnackbarError(true);
      setTimeout(() => setShowSnackbarError(false), 3000);
    }

    // eslint-disable-next-line no-useless-escape
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])[A-Za-z\d@$!%*?&~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{5,}$/;
    if (formdata?.password && formdata.password.match(passwordRegex)) {
      updateData.password = formdata.password;
      passwordValid = true;
    } else {
      setShowSnackbarChangeData(false);
      setShowSnackbarError(true);
      setTimeout(() => setShowSnackbarError(false), 3000);
    }
    if (emailValid && passwordValid) {
      const isOk = await SERVICE.update(updateData);
      console.log(isOk);
      setTimeout(() => setShowSnackbarChangeData(false), 3000);
    }
  };

  const handleFormChange = (event) => {
    setFormdata((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const handleStopFunction = () => {
    setShowMessageLoadOffline(false);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={style.main}>
        <View style={style.nameBanner}>
          <Text style={style.mainHeader}>
            <Text style={style.username}>{username}</Text>
            {username ? `ID: ${id}` : ''}
          </Text>
        </View>
        <View style={style.wrapper}>
          <Text style={style.userData}>Nutzerdaten ändern</Text>
          <View style={style.input}>
            <TextField name="email" label="E-Mail" id="filled-helperText" value={formdata.email} onChange={handleFormChange} style={style.dataInput} />
          </View>
          <View style={style.input}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Neues Passwort</InputLabel>
              <OutlinedInput
                value={formdata.password}
                name="password"
                onChange={handleFormChange}
                onClick={handleClickInput}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      id="toggle-password-visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>

                }
                label="Neues Passwort"
                inputRef={ref}
              />
              {showChecklist && (
                <PasswordChecklist
                  rules={['minLength', 'specialChar', 'number', 'capital']}
                  minLength={5}
                  value={formdata.password}
                  onChange={(isValid) => {
                  }}
                  messages={{
                    minLength: 'Das Passwort enthält mehr als 5 Zeichen',
                    specialChar: 'Das Passwort enthält Sonderzeichen ',
                    number: 'Das Passwort enthält Zahlen',
                    capital: 'Das Passwort enthält Großbuchstaben'
                  }}
                  style={{ fontFamily: Barlow.typography.fontFamily, padding: 10 }}
                />
              )}
            </FormControl>

          </View>
          <Button id="btn_change_data" onClick={handleClickChangeData} style={style.blueButtons}>Angaben Speichern</Button>
        </View>
        <View style={style.wrapper}>
          <Text style={style.header}>Offline Daten herunterladen</Text>
          <Text style={style.text}>Sie können Ihre Daten herunterladen und die App offline weiterhin nutzen. {'\n\n'}Bei Verwendung der Offline-Funktion können bis zu 500 MB an Daten anfallen. {'\n\n'}Es wird empfohlen, die Daten über eine WLAN-Verbindung herunterzuladen.</Text>

          <Button onClick={handleClickOfflineData} style={style.blueButtons}>Offline Daten herunterladen</Button>
        </View>
        <View style={style.logout}>
          <Button onClick={handleLogoutClick} style={style.redButtons}>Logout</Button>
        </View>
      </ScrollView>
      <View>
        <Snackbar
          sx={{ position: 'absolute', bottom: 2 }}
          open={showSnackbarOffline}
          TransitionComponent={SlideTransition}
          message="Diese Funktion steht im Offline Modus nicht zur Verfügung"
          key="snackbar-offline" />

        <View style={{ display: showMessageLoadOffline ? 'block' : 'none', position: 'absolute', bottom: 2, width: '96%', transform: 'translateX(2%)', height: 70, backgroundColor: '#323232', padding: 6, borderRadius: 4, fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontSize: 14 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: '5%' }}>
              <CircularProgress size={20} style={{ marginRight: '10px', color: '#fff' }} />
            </div>
            <div style={{ flex: '90%', color: '#fff' }}>
              <span>
                Bitte warten...<br />
                Die Daten werden heruntergeladen {`${Math.abs(completedRequests)}/${totalCount}`}<br />
                Bitte schließen Sie die App nicht!
              </span>
            </div>
            <div style={{ flex: '5%', position: 'absolute', top: 10, right: 10 }}>
              <TouchableOpacity onPress={handleStopFunction}>
                <span>
                  <CloseIcon style={{ color: '#fff' }} size={20} />
                </span>
              </TouchableOpacity>
            </div>
          </div>
        </View>
        <Snackbar
          sx={{ position: 'absolute', bottom: 2 }}
          open={showSnackbarLoadOfflineDone}
          TransitionComponent={SlideTransition}
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineIcon size={20} style={{ marginRight: '10px', color: '#fff' }} />
              <span>Daten wurden heruntergeladen</span>
            </div>
          }
          key="snackbar-load-offline-done" />
        <Snackbar
          sx={{ position: 'absolute', bottom: 2 }}
          open={showSnackbarChangeData}
          TransitionComponent={SlideTransition}
          message={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleOutlineIcon size={20} style={{ marginRight: '10px', color: '#fff' }} />
              <span>Daten wurden gepeichert</span>
            </div>
          }
          key="snackbar-change-data"
        />
        <Snackbar
          sx={{ position: 'absolute', bottom: 2 }}
          open={showSnackbarError}
          TransitionComponent={SlideTransition}
          key="snackbar-error">
          <Alert severity="error" sx={{ width: '100%' }}>
            Bitte überprüfen Sie Ihre Eingabe!
          </Alert>
        </Snackbar>
      </View>
    </View>
  );
}
