import MPRestService from './MPRestService';

class AuthService extends MPRestService {
  endpoint;

  constructor() {
    super();
    this.endpoint = '/auth';
  }

  /**
   *
   * @param {*} username
   * @param {*} password
   * @returns {Promise<{isAuthorized, email, id, username, password, name, last_name}>} authData
   */
  async login(username, password) {
    const data = {
      username,
      password
    };

    try {
      return await this._post(this.endpoint, data);
    } catch (e) {
      throw new Error('Login fehlgeschlagen. Bitte prüfen Sie ihre Angaben.');
    }
  }
}

export default AuthService;
