import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AmAlphaLogo from './../Common/AmAlphaLogo';
import {Text, View, StyleSheet, TouchableOpacity} from 'react-native';
import {theme, Barlow, Bitter} from '../../utils/theme';
import SCREEN from '../../utils/contants/SCREEN';
import {useNavigation} from '@react-navigation/native';
import {useContext} from 'react';
import {AppContext} from '../../contexts/AppContext';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'red',
    minHeight: 75,
    flexGrow: 1,
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  logo: {
    marginLeft: 'auto',
  },
  upperText: {
    fontFamily: Barlow.typography.fontFamily,
    fontWeight: Barlow.typography.fontWeightLight,
    fontSize: 16,
  },
  title: {
    marginRight: 'auto',
    color: theme.palette.primary.dark,
    fontFamily: Bitter.typography.fontFamily,
    fontWeight: Bitter.typography.fontWeightMedium,
    fontSize: 26,
  },
  realEstateName: {
    marginRight: 'auto',
    color: theme.palette.primary.dark,
    fontFamily: Bitter.typography.fontFamily,
    fontWeight: Bitter.typography.fontWeightMedium,
    fontSize: 20,
    flexWrap: 'wrap',
    width: 275,
  },
  tallContainer: {
    backgroundColor: 'red',
    minHeight: 93,
    flexGrow: 1,
  },
});

export default function MPAppBar(props) {
  const {setActiveTab, setSearchTerm, setIsSearchBarOpen} = useContext(AppContext);
  const navigation = useNavigation();

  const handlePress = () => {
    setSearchTerm('');
    navigation.setParams({continent: ''});
    setIsSearchBarOpen(false);
    setActiveTab(0);
    navigation.navigate(SCREEN.HOME, {});
  };

  return (
      <View style={props.tall ? styles.tallContainer : styles.container}>
        <AppBar position="static" sx={{background: '#CFE0EB', height: '100%', boxShadow: 'none'}}>
          <Toolbar style={styles.toolbar}>
            <View>
              {props.hideTitle ? null : (
                  <>
                    <Text style={styles.upperText}>{props.options.upperText}</Text>
                    <Text style={styles.title}>{props.options.tabBarLabel}</Text>
                  </>
              )}
              <Text style={styles.title}>{props.options.homeText}</Text>
              <Text style={styles.realEstateName}>{props.options.realEstateName}</Text>
            </View>
            <TouchableOpacity onPress={handlePress}>
              <AmAlphaLogo style={styles.logo} width={55} height={55}/>
            </TouchableOpacity>
          </Toolbar>
        </AppBar>
      </View>
  );
}
