import { BACKEND_API_GATEWAY } from '../utils/contants/BACKEND_API_GATEWAY';
import MPRestService from './MPRestService';

class RealEstateService extends MPRestService {
  endpoint;
  credentials;
  constructor(credentials = {}) {
    super(credentials);
    this.endpoint = '/estates';
    this.credentials = credentials;
  }

  async getAll_old() {
    const allIDs = await this.getAllIDs();
    const allData = [];

    // loop through ids and get all data
    for (let i = 0; i < allIDs.length; i++) {
      const oneEstate = await this.get(allIDs[i]);
      allData.push(oneEstate);
    }

    return allData;
  }

  async getAll() {
    return await this._get(this.endpoint);
  }

  async get(id) {
    if (!id) {
      return null;
    }
    const oneEstate = await this._get(this.endpoint + '/' + id);
    return oneEstate;
  }

  async getAllIDs() {
    // get all ids from backend with _get
    const allIds = await this._get(this.endpoint, { all: true });
    return allIds;
  }

  static getAPIURL() {
    return `${BACKEND_API_GATEWAY}/estates`;
  }
}

export default RealEstateService;
