export default function destroyIOSStupidScroll(window) {
  const handleTouchmove = (event) => {
    let startY = localStorage.getItem('startY') || 0;
    let el = event.target;

    // Allow zooming
    let zoom = window.innerWidth / window.document.documentElement.clientWidth;
    if (event.touches.length > 1 || zoom !== 1) {
      return;
    }

    // Recursive check up the dom tree, starting from the touched element
    while (el !== document.body && el !== document) {
      let style = window.getComputedStyle(el);
      if (!style) {
        break;
      }

      if (el.nodeName === 'INPUT' && el.getAttribute('type') === 'range') {
        return;
      }

      let scrolling = style.getPropertyValue('-webkit-overflow-scrolling');
      let overflowY = style.getPropertyValue('overflow-y');
      let height = parseInt(style.getPropertyValue('height'), 10);

      let shouldScroll = scrolling === 'touch' && (overflowY === 'auto' || overflowY === 'scroll');
      let canScroll = el.scrollHeight > el.offsetHeight;
      if (shouldScroll && canScroll) {
        let curY = event.touches ? event.touches[0].screenY : event.screenY;
        let isAtTop = (startY <= curY && el.scrollTop === 0);
        let isAtBottom = (startY >= curY && el.scrollHeight - el.scrollTop === height);
        if (isAtTop || isAtBottom) {
          event.preventDefault();
        }

        // handled scrolling, stop going up
        return;
      }

      // not handled scrolling, going up the DOM
      el = el.parentNode;
    }

    // prevent bounce when overscroll in iOS
    // we reached this line because there was no scrolling element
    // we dont reach this line when we handled a scrolling element above
    event.preventDefault();
  };

  const handleTouchstart = (event) => {
    localStorage.setItem('startY', event.touches ? event.touches[0].screenY : event.screenY);
  };

  // Let'sa go, Mario!	
  window.addEventListener('touchstart', handleTouchstart, { passive: false });
  window.addEventListener('touchmove', handleTouchmove, { passive: false });
}