import { Button, TextField, ThemeProvider, useTheme } from '@mui/material';
import * as React from 'react';
import { useContext, useState } from 'react';
import { Linking, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import AmAlphaLogoFull from '../components/Common/AmAlphaLogoFull';
import HeaderImage from '../components/Common/HeaderImage';
import { AppContext } from '../contexts/AppContext';
import AuthService from '../services/AuthService';
import MPStorageService from '../services/MPStorageService';
import SCREEN from '../utils/contants/SCREEN';
import { Barlow, Bitter, theme } from '../utils/theme';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import FormControl from '@mui/material/FormControl';
import { AM_ALPHA_HOMEPAGE } from '../utils/contants/BACKEND_API_GATEWAY';

const styles = StyleSheet.create({
  logo: {
    width: 90,
    height: 90,
    left: '50%',
    top: '100%',
    position: 'absolute',
    transform: [{ translateX: -45 }, { translateY: -45 }]
  },
  wrapper: {
    marginTop: '22%',
    marginLeft: '5%',
    marginRight: '5%',
    width: '90%',
    height: 'auto',
    backgroundColor: '#fff',
    borderRadius: 5
  },
  login: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontSize: 28,
    marginTop: '9%',
    marginBottom: '7%',
    fontFamily: Bitter.typography.fontFamily,
    fontWeight: Bitter.typography.fontWeightMedium
  },
  input: {
    backgroundColor: '#eef0f3',
    marginBottom: '5%',
    fontFamily: Barlow.typography.fontFamily,
    fontWeight: Barlow.typography.fontWeightMedium
  },
  inputLabelStyle: {
    color: theme.palette.primary.main,
    fontWeight: Barlow.typography.fontWeightMedium,
    fontFamily: Barlow.typography.fontFamily
  },

  btnForgotPassword: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    marginBottom: '7%',
    fontFamily: Barlow.typography.fontFamily,
    fontWeight: Barlow.typography.fontWeightBold,
    fontSize: 16
  },
  errorMsg: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    fontFamily: Barlow.typography.fontFamily,
    padding: 15,
    marginBottom: 10,
    border: 'none',
    borderRadius: 0,
    fontSize: 16

  },
  btnLogin: {
    height: 50,
    backgroundColor: theme.palette.secondary.main,
    marginBottom: '7%',
    textTransform: 'none',
    fontFamily: Barlow.typography.fontFamily
  }
});

export default function Login({ navigation }) {
  const theme = useTheme();
  const Storage = new MPStorageService();
  const { setAppContext } = useContext(AppContext);

  const [formProps, setFormProps] = useState({ username: '', password: '' });
  const [accessGranted, setAccessGranted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleFormChange = (event) => {
    setFormProps((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value
      }
    });
  }

  const handleLoginClick = async (event) => {
    event.preventDefault();

    // Validierung der Anmeldeinformationen
    if (!formProps.username.trim() || !formProps.password.trim()) {
      setErrorMsg('Login fehlgeschlagen. Bitte prüfen Sie ihre Angaben.');
      setAccessGranted(false);
      return;
    }

    // Login
    const Auth = new AuthService();
    try {
      const authData = await Auth.login(formProps.username, formProps.password);
      if (authData.isAuthorized) {
        setAccessGranted(true);
        await saveCredentials(authData);
        navigation.navigate(SCREEN.INNER_APP, {});
      } else {
        throw new Error('Login fehlgeschlagen. Bitte prüfen Sie ihre Angaben.');
      }
    } catch (error) {
      console.error(error);
      setErrorMsg(error.message);
      setAccessGranted(false);
    }
  };

  /**
   * Save credentials to Storage and appContext after auth
   */
  const saveCredentials = async (authData) => {
    // Speichern der Anmeldeinformationen im appContext
    setAppContext((prevState) => {
      return {
        ...prevState,
        auth: {
          username: formProps.username,
          password: formProps.password,
          email: authData.email,
          id: authData.id,
          name: authData.name,
          last_name: authData.last_name,
          timestamp: Date.now()
        }
      }
    });

    // Speichern der Anmeldeinformationen im Storage
    await Storage.setCredentials(
      JSON.stringify({
        username: formProps.username,
        password: formProps.password,
        timestamp: Date.now()
      })
    );
  };

  const handlePress = () => {
    window.open('https://old.amalpha.de/rs-pwa-pw.php', '_self');
  }

  return (
    <View>
      <View style={{ position: 'relative' }}>
        <HeaderImage />
        <View style={styles.logo}>
          <AmAlphaLogoFull width={'100%'} height={'100%'} resizeMode={'contain'} />
        </View>
      </View>
      <View style={styles.wrapper}>
        <ThemeProvider theme={theme}>
          <Text style={styles.login}>Login</Text>
        </ThemeProvider>
        <View style={{ margin: '5%' }}>

          {
            !accessGranted
              ? (
                <Text style={errorMsg !== null && !accessGranted ? styles.errorMsg : { display: 'none' }}>{errorMsg}</Text>
              )
              : null
          }

          <TextField
            id="username-input"
            name="username"
            label="Benutzername"
            style={styles.input}
            InputLabelProps={StyleSheet.flatten({ style: styles.inputLabelStyle })}
            value={formProps.username}
            onChange={handleFormChange}
            error={errorMsg !== null && !accessGranted}
          />
          <FormControl variant="outlined"
            style={styles.input}>
            <InputLabel htmlFor="outlined-adornment-password"
              style={styles.inputLabelStyle}
            >Passwort</InputLabel>
            <OutlinedInput
              value={formProps.password}
              name="password"
              onChange={handleFormChange}

              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              error={errorMsg !== null && !accessGranted}
              label="Passwort"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    id="toggle-password-visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>

              }
            />
          </FormControl>

          <Button onClick={handleLoginClick} style={styles.btnLogin} variant="contained">Anmelden</Button>

          <TouchableOpacity onPress={handlePress}>
            <Text style={styles.btnForgotPassword}>Passwort vergessen?</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
