import { useNavigation } from '@react-navigation/core';
import React, { useState, memo, useEffect } from 'react';

import {
  Image,
  StyleSheet,
  Text,
  View
} from 'react-native';
import Icon from '../../../assets/icons/realestate-street-logo.svg';
import RealEstateService from '../../services/RealEstateService';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AssetService from '../../services/AssetService';
import { BACKEND_API_GATEWAY } from '../../utils/contants/BACKEND_API_GATEWAY';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#f7f7f5',
    padding: 8,
    marginBottom: 8,
    width: '100%'
  },
  imageContainer: {
    marginRight: 10
  },
  image: {
    width: 60,
    height: 65
  },
  infoContainer: {
    flex: 1
  },
  countryLocation: {
    fontSize: 14,
    color: '#666'
  },
  name: {
    fontSize: 16,
    fontWeight: '600'
  },
  acquisition: {
    fontSize: 14,
    color: '#666'
  },
  addressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    marginRight: 4
  },
  street: {
    fontSize: 14,
    fontWeight: '700',
    color: '#28465a'
  },
  moreInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  moreInfo: {
    fontSize: 14,
    fontWeight: '700',
    color: '#C51F40'
  }
});

const RealEstateListItem = ({
  street,
  name,
  country,
  location,
  acquisition,
  moreinfo,
  pictures
}) => {
  const [srcURL, setSrcURL] = useState(null);

  const handleImageLoad = async () => {
    const url = `${BACKEND_API_GATEWAY}/img/${pictures[0]}`;
    setSrcURL(await AssetService.loadIMG(url));
  };

  useEffect(() => {
    handleImageLoad();
  }, [])

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <img
          src={srcURL}
          style={styles.image}
        />
      </View>
      <View style={styles.infoContainer}>
        <Text style={styles.countryLocation}>
          {country} / {location}
        </Text>
        <Text style={styles.name}>{name}</Text>
        <Text style={styles.acquisition}>Completion: {acquisition}</Text>
        {street && (
          <View style={styles.addressContainer}>
            <Image style={styles.icon} source={Icon} />
            <Text style={styles.street}>{street}</Text>
          </View>
        )}
        <Text>{moreinfo}</Text>
      </View>
    </View>
  );
};

export default memo(RealEstateListItem);
