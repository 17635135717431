import Icon from '../../../assets/images/am-alpha-logo.svg';
import { Image } from 'react-native';

const AmAlphaLogo = ({ width, height }) => {
    let _w = width || 50;
    let _h = height || 50;

    return (
        <Image
            style={{ width: _w, height: _h }}
            source={Icon}
        />
    );
}

export default AmAlphaLogo;