import MPRestService from "./MPRestService";

class UserService extends MPRestService {
    endpoint;
    constructor() {
        super();
        this.endpoint = '/users';
    }

    /**
     * 
     * @param {{id, email, password}} updateData
     * @returns 
     */
    async update(updateData) {
        if (!updateData.id) {
            return null;
        }
        const newID = await this._patch(this.endpoint + '/' + updateData.id, updateData);
        return newID;
    }
}

export default UserService;