import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import MPAppBar from '../components/Layout/MPAppBar';
import MPBottomNavigationBar from '../components/Layout/MPBottomNavigationBar';
import SCREEN from '../utils/contants/SCREEN';
import Analysis from './Analysis';
import Home from './Home';
import Profile from './Profile';
import RealEstateList from './RealEstateList';
import MPStorageService from '../services/MPStorageService';

const Tab = createBottomTabNavigator();

const InnerApp = ({ route, navigation }) => {
  const screenOptions = {
    title: ''
  };
  const Storage = new MPStorageService();

  async function loginExpired() {
    const credentials = await Storage.getCredentials();
    if (credentials && ((Date.now() - credentials.timestamp) / (1000 * 60 * 60 * 24)) > 7) {
      navigation.navigate(SCREEN.LOGIN, {});
    }
  }

  loginExpired();

  return (
    <Tab.Navigator
      tabBar={props => <MPBottomNavigationBar {...props} />}
      initialRouteName={SCREEN.HOME}
    >{/* children components are screen components, tabbed navigation */}
      <Tab.Screen
        name={SCREEN.HOME} component={Home}
        options={{
          ...screenOptions,
          header: (props) => <MPAppBar {...props} hideTitle />,
          headerStyle: { height: 200, backgroundColor: 'black' },
          tabBarLabel: 'Home',
          homeText: 'AM alpha App'
        }}
      />
      <Tab.Screen
        name={SCREEN.ANALYSIS} component={Analysis}
        options={{
          ...screenOptions,
          header: (props) => <MPAppBar {...props} />,
          tabBarLabel: 'Analyse'
        }}
      />
      <Tab.Screen
        name={SCREEN.REAL_ESTATE_LIST} component={RealEstateList}
        options={{
          ...screenOptions,
          header: (props) => <MPAppBar {...props} />,
          upperText: 'AM Alpha',
          tabBarLabel: 'Objekte'
        }}
      />
      <Tab.Screen
        name={SCREEN.PROFILE} component={Profile}
        options={{
          ...screenOptions,
          header: (props) => <MPAppBar {...props} />,
          tabBarLabel: 'Profil'
        }}
      />
    </Tab.Navigator>
  );
};

export default InnerApp;
