
const SCREEN = {
    LOGIN: 'LOGIN',
    INNER_APP: 'INNER_APP',
    HOME: 'HOME',
    REAL_ESTATE_LIST: 'REAL_ESTATE_LIST',
    REAL_ESTATE_DETAIL: 'REAL_ESTATE_DETAIL',
    ANALYSIS: 'ANALYSIS',
    PROFILE: 'PROFILE'
};
export default SCREEN;