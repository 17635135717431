import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  View, Modal, TouchableOpacity, StyleSheet, Image
} from 'react-native';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BACKEND_API_GATEWAY } from '../../utils/contants/BACKEND_API_GATEWAY';
import AssetService from '../../services/AssetService';
import ImageViewer from 'react-native-image-zoom-viewer';
import CloseIcon from '@mui/icons-material/Close';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fullscreenImage: {
    width: '100%',
    height: '100%'
  }
});

const ImageSlider = ({ route, navigation, estate }) => {
  const [data, setData] = useState(estate);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);

  useEffect(() => {
    const init = async () => {
      const urls = [];
      for (let i = 0; i < estate.picture_list_ids.length; i++) {
        const fileName = estate.picture_list_ids[i];
        const src = await AssetService.loadIMG(`${BACKEND_API_GATEWAY}/img/${fileName}`);
        urls.push(src);
      }
      setImageUrls(urls);
      setLoading(false);
    };
    navigation.setParams({ name: data.name });
    init();
  }, []);

  const handleItemClick = (index) => {
    setClickedImageIndex(index);
  };

  const handleCloseModal = () => {
    setClickedImageIndex(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <CircularProgress sx={{ color: '#c51f40' }} />
      </Box>);
  }

  const indicatorStyles = {
    background: '#C51F40',
    width: 25,
    height: 4,
    display: 'inline-block',
    margin: '0 8px',
    borderRadius: 'unset'
  };

  const activeIndicatorStyles = {
    ...indicatorStyles,
    background: '#FFFFFF',
    height: 2
  };

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    bottom: '5%',
    width: 50,
    height: 50,
    cursor: 'pointer',
    color: '#fff',
    backgroundColor: '#C51F40',
    borderRadius: 5,
    border: 'none'
  };

  return (
    <View style={{ alignItems: 'center' }}>
      <View>
        <Carousel
          onClickItem={handleItemClick}
          showThumbs={false}
          infiniteLoop={true}
          showArrows={true}
          dynamicHeight={false}
          showStatus={false}
          showIndicators={imageUrls && imageUrls.length > 1}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...arrowStyles, left: 0 }}
              >
                <ArrowForwardIosOutlinedIcon style={{ transform: 'rotate(180deg)', fontSize: 'xx-large' }} />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...arrowStyles, right: 0 }}
              >
                <ArrowForwardIosOutlinedIcon style={{ fontSize: 'xx-large' }} />
              </button>
            )
          }
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const styles = isSelected ? indicatorStyles : activeIndicatorStyles;
            return (
              <li
                style={{ ...styles }}
                key={index}
                onClick={onClickHandler}
                role="button"
                tabIndex={0}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}

        >
          {imageUrls && imageUrls.map((image, index) => (
            <img
              key={index}
              src={image}
              style={{ width: '100%', height: 300, objectFit: 'cover' }}
              alt={index}

            />
          ))}

        </Carousel>
        {clickedImageIndex !== null && (
          <Modal visible={true} transparent={true}>
            <TouchableOpacity
              style={styles.modalContainer}
              activeOpacity={1}
              onPress={handleCloseModal}
            >
              <ImageViewer
                imageUrls={imageUrls.map((url) => ({ url }))}
                renderIndicator={() => null}
                enableSwipeDown={true}
                onCancel={handleCloseModal}
                index={clickedImageIndex}
                enablePreload={true}
                style={styles.fullscreenImage}
                saveToLocalByLongPress={false}
              />
              <TouchableOpacity
                style={{ position: 'absolute', top: '5%', right: 10 }}
                onPress={handleCloseModal}
              >
                <CloseIcon style={{ fontSize: 30, color: '#fff' }} />
              </TouchableOpacity>
            </TouchableOpacity>
          </Modal>
        )}

      </View>
    </View>
  );
};

export default ImageSlider;
