import MPStorageService from './MPStorageService';
import RealEstateService from './RealEstateService';

class AssetService {
  static CACHE_NAME = 'offline-assets'
  /**
     * Load img with network first strategy
     * @param {string} url
     */
  static async loadIMG(url) {
    /**
     * Load image from /api/img
     */
    const loadFromNetwork = async (url) => {
      return await fetch(url);
    }
    /**
     * Load img from caches
     */
    const loadFromCache = async (url) => {
      const cache = await caches.open(this.CACHE_NAME)
      return await cache.match(url);
    }

    // do job
    let res = '';
    let result = null;
    try {
      res = await loadFromNetwork(url);
      result = URL.createObjectURL(await res.blob());
      this.saveToCache(url);
    } catch (error) {
      console.log('Network error, try load cache');
      try {
        res = await loadFromCache(url);
        result = URL.createObjectURL(await res.blob());
      } catch (error) {
        console.log(error);
        console.error('Could not load from cache: ' + url);
      }
    }
    return result;
  }

  /**
   * Load estates data, network-first, offline fallback
   * @returns []
   */
  static async loadMainListData() {
    /**
     * Load estates from /api/estates
     */
    const loadFromNetwork = async () => {
      const Storage = new MPStorageService();
      const credentials = await Storage.getCredentials();
      const SERVICE = new RealEstateService(credentials);
      const data = await SERVICE.getAll();
      return data;
    }
    /**
     * Load estates from caches
     */
    const loadFromCache = async (url) => {
      const cache = await caches.open(this.CACHE_NAME);
      return await cache.match(url);
    }

    // do job
    const url = RealEstateService.getAPIURL()
    let result = null;
    try {
      result = await loadFromNetwork();
      this.saveToCache(url);
    } catch (error) {
      console.log('Network error, try load cache');
      try {
        const res = await loadFromCache(url);
        result = await res.json();
      } catch (error) {
        console.log(error);
        console.error('Could not load from cache: ' + url);
      }
    }
    return result;
  }

  static saveToCache(url) {
    navigator.serviceWorker.controller.postMessage({
      type: 'CACHE',
      url
    });
  }
}

export default AssetService;
