import axios from 'axios';
import { BACKEND_API_GATEWAY } from '../utils/contants/BACKEND_API_GATEWAY';

class MPRestService {
  restClient;
  constructor(credentials = {}) {
    const axiosConfig = {
      baseURL: BACKEND_API_GATEWAY,
      timeout: 5000,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Custom-Header': 'AMALPHA'
      }
    };

    if (credentials) {
      axiosConfig.auth = {
        username: credentials?.username,
        password: credentials?.password
      }
    }
    this.restClient = axios.create(axiosConfig);
  }

  async _patch(endpoint, patchData) {
    try {
      const response = await this.restClient.patch(endpoint, patchData);
      return response.data;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  async _post(endpoint, postData) {
    try {
      const response = await this.restClient.post(endpoint, postData);
      return response.data;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  async _get(endpoint, queryParams) {
    try {
      const response = await this.restClient.get(endpoint, { params: queryParams });
      return response.data;
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  handleError(error) {
    if (error.response) {
      // The server did not response with a 2xx Code
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // No response was received
      console.log(error.request);// XMLHttpRequest that was made
    } else {
      // Keine Ahnung
      console.log('Error', error.message);
    }
    console.log(error.config);
  }
}

export default MPRestService;
