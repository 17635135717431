import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native';
import ImageSlider from '../components/RealEstateDetail/ImageSlider';
import RealEstateInfoPanel from '../components/RealEstateDetail/RealEstateInfoPanel';
import { Barlow } from '../utils/theme';
import { useNavigation } from '@react-navigation/native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f2f2f2',
    marginHorizontal: 15,
    height: '900px'
  },
  backButton: {
    marginVertical: 15,
    fontSize: 15,
    fontFamily: Barlow.typography.fontFamily,
    fontWeight: Barlow.typography.fontWeightMedium,
    color: '#28465A'
  }
});

export default function RealEstateDetail({ route, navigation }) {
  navigation = useNavigation();
  const { estate } = route.params;
  const handlePress = () => {
    navigation.goBack();
  };

  return (
    <ScrollView style={styles.container}>
      <TouchableOpacity onPress={handlePress}>
        <Text style={styles.backButton}><ArrowBackIosNewOutlinedIcon style={{ fontSize: 'small', verticalAlign: 'middle' }} />zurück</Text>
      </TouchableOpacity>
      <ImageSlider estate={estate} route={route} navigation={navigation}></ImageSlider>
      <RealEstateInfoPanel estate={estate} route={route} navigation={navigation}></RealEstateInfoPanel>
    </ScrollView>
  );
}
