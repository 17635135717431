import Icon from '../../../assets/images/am-alpha-logo-full.svg';
import {Image} from 'react-native';

const AmAlphaLogoFull = ({width, height}) => {
  let _w = width || 90;
  let _h = height || 90;

  return (
      <Image
          style={{width: _w, height: _h}}
          source={Icon}
      />
  );
};

export default AmAlphaLogoFull;