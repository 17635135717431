import {createTheme} from '@mui/material';
import '../fonts/barlow-v12-latin/font.css';
import '../fonts/bitter-v32-latin/font.css';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
      light: '#6f6e6e',
      dark: '#191b1d',
    },
    secondary: {
      main: '#c51f40',
      light: '#eef0f3',
      dark: '#fff',

    },
  },
});

export const Bitter = createTheme({
  typography: {
    fontFamily: 'Bitter',
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',

  },
});
export const Barlow = createTheme({
  typography: {
    fontFamily: 'Barlow',
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',
  },
});