import { Search } from '@mui/icons-material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Fab } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  FlatList,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';
import RealEstateListItem from '../components/RealEstateList/RealEstateListItem';
import SCREEN from '../utils/contants/SCREEN';
import RealEstateService from './../services/RealEstateService';
import AssetService from '../services/AssetService';
import { Barlow } from '../utils/theme';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { AppContext } from '../contexts/AppContext';

const styles = StyleSheet.create({
  scrolltoTopBar: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderBottomWidth: 2,
    borderBottomColor: 'gray',
    alignItems: 'center',
    color: '#5c7e94'
  },
  wrapper: {
    flex: 1
  },
  searchInput: {
    minHeight: 40,
    minWidth: 300,
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 30,
    backgroundColor: '#fff'
  },
  Icons: {
    color: 'white'
  },
  searchResult: {
    textAlign: 'center',
    fontSize: 20,
    color: '#C51F40',
    padding: 20
  },
  searchButton: {
    height: 70,
    transition: 'all 0.3s ease-in-out',
    position: 'absolute',
    bottom: 50,
    right: 20,
    zIndex: 1
  },
  searchBar: {
    alignItems: 'center',
    border: '3px solid #a5a3a3',
    position: 'absolute',
    bottom: 66,
    right: 40,
    zIndex: 1,
    opacity: 1,
    transition: 'all 0.3s ease-in-out',
    backgroundColor: '#a5a3a3',
    display: 'flex',
    borderRadius: 10,
    margin: 3,
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
  },
  searchBack: {
    textAlign: 'center',
    fontSize: 16,
    color: '#000'
  },
  backButton: {
    marginVertical: 15,
    fontSize: 15,
    fontFamily: Barlow.typography.fontFamily,
    fontWeight: Barlow.typography.fontWeightMedium,
    color: '#28465A',
    marginLeft: 16
  },
  moreInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  moreInfo: {
    fontSize: 14,
    fontWeight: '700',
    color: '#C51F40'
  }
});

export default function RealEstateList({ route, navigation }) {
  const [data, setData] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const inputRef = useRef(null);
  const flatListRef = useRef(null);
  const [currentContinent, setCurrentContinent] = useState(null);
  const [showOverviewButton, setShowOverviewButton] = useState(false);

  const { continent } = route.params || {};

  useEffect(() => {
    setCurrentContinent(continent);
    setShowOverviewButton(!!continent);
  }, [continent]);

  const { setActiveTab, searchTerm, setSearchTerm, isSearchBarOpen, setIsSearchBarOpen } = useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      const data = await AssetService.loadMainListData();
      setData(data);
    }

    fetchData();
  }, []);

  const handleScroll = (event) => {
    const offsetY = event.nativeEvent.contentOffset.y;
    setIsScrolled(offsetY > 0);
  };

  const handleScrollToTop = () => {
    flatListRef.current?.scrollToOffset({ offset: 0, animated: true });
  };

  const handleOpenSearchBar = () => {
    setIsSearchBarOpen(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 300);
  };

  const handleCloseSearchBar = () => {
    setIsSearchBarOpen(false);
  };

  const handlebackClick = () => {
    setSearchTerm('');
    setIsSearchBarOpen(false);
  };

  const filteredData = data?.filter((item) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const searchNumeric = !isNaN(searchTermLowerCase);
    const searchNumber = searchNumeric ? parseFloat(searchTermLowerCase) : null;

    const nameMatch = item.name.toLowerCase().includes(searchTermLowerCase);
    const acquisitionMatch = item.acquisition.toString().includes(searchTermLowerCase);
    const dateofsaleMatch = item.dateofsale.toString().includes(searchTermLowerCase);
    const renovationMatch = item.renovation.toString().includes(searchTermLowerCase);
    const geographicMatch = item.street.toLowerCase().includes(searchTermLowerCase) ||
      item.location.toLowerCase().includes(searchTermLowerCase) ||
      item.district.toLowerCase().includes(searchTermLowerCase) ||
      item.country.toLowerCase().includes(searchTermLowerCase);
    const financialsMatch = item.fond.toLowerCase().includes(searchTermLowerCase) ||
      item.split.toLowerCase().includes(searchTermLowerCase) ||
      item.estimatedexitprice.toString().includes(searchTermLowerCase) ||
      item.estimatednetprofit.toString().includes(searchTermLowerCase) ||
      item.fairvalue.toString().includes(searchTermLowerCase) ||
      item.externalloan.toString().includes(searchTermLowerCase) ||
      item.sellingprice.toString().includes(searchTermLowerCase) ||
      item.totalinvestment.toString().includes(searchTermLowerCase);
    const continentMatch = !currentContinent || item.continent === currentContinent;
    const continentSearch = currentContinent ? currentContinent.toLowerCase() : '';

    return (
      nameMatch ||
      acquisitionMatch ||
      dateofsaleMatch ||
      renovationMatch ||
      geographicMatch ||
      financialsMatch
    ) && continentMatch;
  });

  const filteredRenderItem = ({ item }) => {
    return (
      <TouchableOpacity onPress={() => navigation.navigate(SCREEN.REAL_ESTATE_DETAIL, { uid: item.uid, estate: item })}>
        <RealEstateListItem
          uid={item.uid}
          pictures={item.picture_list_ids}
          country={item.country}
          location={item.location}
          name={item.name}
          acquisition={item.acquisition}
          street={item.street}
          moreinfo={<View style={styles.moreInfoContainer}>
            <Text style={styles.moreInfo}>
              Mehr erfahren{' '}
              <ArrowForwardIosOutlinedIcon
                style={{ verticalAlign: 'middle', fontSize: 'medium' }}
              />
            </Text>
          </View>
          }
          navigation={navigation}
          currentContinent={currentContinent}
        />
      </TouchableOpacity>

    );
  };

  return (
    <View style={styles.wrapper}>
      {isSearchBarOpen
        ? (
          <Box
            style={styles.searchBar}
          >
            <TextInput
              ref={inputRef}
              style={styles.searchInput}
              placeholder="Standort eingeben..."
              value={searchTerm}
              onChangeText={setSearchTerm}

            />

          </Box>
        )
        : null}
      <Box
        style={styles.searchButton}
      >
        <Fab color="secondary" aria-label="openSearch" onClick={handleOpenSearchBar}>
          {isSearchBarOpen
            ? (
              <TouchableOpacity onPress={handleCloseSearchBar}>
                <Search style={styles.Icons} />
              </TouchableOpacity>
            )
            : (
              <Search style={styles.Icons} />
            )}
        </Fab>
      </Box>
      <View
        style={{
          height: isScrolled ? 50 : 0,
          overflow: 'hidden',
          opacity: isScrolled ? 1 : 0,
          transition: 'all 0.3s ease-in-out'
        }}>
        <TouchableOpacity
          style={styles.scrolltoTopBar}
          onPress={handleScrollToTop}>
          <ArrowForwardIosOutlinedIcon style={{ transform: 'rotate(270deg)' }} />
        </TouchableOpacity>
      </View>
      {showOverviewButton && (
        <TouchableOpacity onPress={() => {
          navigation.navigate(SCREEN.HOME, {});
          setActiveTab(0);
          setSearchTerm('');
          setIsSearchBarOpen(false);
        }}>
          <Text style={styles.backButton}><ArrowBackIosNewOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'middle' }} /> Kontinentauswahl</Text>
        </TouchableOpacity>
      )}

      {filteredData?.length > 0
        ? (
          <FlatList
            ref={flatListRef}
            data={filteredData}
            renderItem={filteredRenderItem}
            keyExtractor={(item) => item.uid}
            onScroll={handleScroll}
            scrollEventThrottle={16}
          />
        )
        : (
          <View>
            {data?.length > 0
              ? (
                <>
                  <Text style={styles.searchResult}>&quot;{searchTerm.substring(0, 15)}{searchTerm.length > 15 ? '...' : ''}&quot; wurde nicht gefunden</Text>
                  <TouchableOpacity onPress={handlebackClick}>
                    <Text style={styles.searchBack}>zurück zur Übersicht</Text>
                  </TouchableOpacity>
                </>
              )
              : (
                <Text style={styles.searchResult}>keine Daten vorhanden</Text>
              )}
          </View>
        )}
    </View>
  );
};
